import { SectionFeaturedBlogPostsProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Chip, Link, Media, SectionWrapper } from '@portfolio/components'
import { useTranslation } from 'react-i18next'

const StyledSectionWrapper = styled(SectionWrapper, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingInline: 20,

  '@xl': {
    paddingInline: 30,
  },
})
const Heading = styled('h2', {
  projectFont: 'heading02',
  color: '$white',
  textAlign: 'center',
  marginBottom: '$96',

  '@xl': {
    marginBottom: '$120',
  },
})
const CardLink = styled(Link, {
  pointerEvents: 'all',
  zIndex: '$cardButton',
  width: 'max-content',
})
const CardsWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 30,
  marginBottom: '$40',

  '@xl': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  '@media (min-width: 1522px)': {
    gridTemplateColumns: 'unset',
    gridAutoFlow: 'column',
    gridAutoColumns: 486,
  },
})

const Article = styled('div', {
  position: 'relative',
  width: '100%',
  color: '$white',
})

const ArticleTitle = styled('h2', {
  projectFont: 'heading07',
  gridRow: 2,
  gridColumn: 1,
  color: '$white',
})

const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  borderRadius: '$r2',
  overflow: 'hidden',
  transition: 'transform .5s ease',

  '& img': {
    transition: 'transform .5s ease',
  },

  '@md': {
    '&:hover': {
      '& img': {
        transform: 'scale(1.04)',
      },
    },
  },
})

const ArticleTags = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gridColumn: 1,
  gridRow: 1,
  columnGap: '$12',
})

const ArticleTag = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
})

const ArticleGridContent = styled('div', {
  position: 'relative',
  display: 'grid',
  rowGap: '$24',
  width: '100%',
  paddingTop: '$20',

  '@md': {
    rowGap: '$32',
  },
})

const StyledLink = styled(Link, {
  zIndex: '$content',
  projectFont: 'heading08',
  textDecoration: 'underline',
  gridColumn: 1,
  gridRow: 3,
  textUnderlineOffset: '.35em',
  color: '$white',
})

export const SectionFeaturedBlogPosts = ({
  title,
  featuredArticles,
  priority,
  ctaLabel,
  ctaLink,
}: SectionFeaturedBlogPostsProps) => {
  const { t } = useTranslation('articles')

  return (
    <StyledSectionWrapper type="DEFAULT">
      {title && <Heading>{title}</Heading>}
      <CardsWrap>
        {featuredArticles?.map(({ link, image, title, tags }) => (
          <Article key={link}>
            {image && (
              <a href={link} tabIndex={-1} aria-label={`Article - ${title}`}>
                <MediaWrap>
                  <Media
                    {...image}
                    hardcropRatio={'landscape3'}
                    priority={priority}
                    sizes="(min-width: 740px) 50vw, 100vw"
                  />
                </MediaWrap>
              </a>
            )}
            <ArticleGridContent>
              {tags && (
                <ArticleTags>
                  <ArticleTag>
                    {tags.map((tag) => (
                      <Chip title={tag.title} type="light" disabled />
                    ))}
                  </ArticleTag>
                </ArticleTags>
              )}

              {title && <ArticleTitle>{title}</ArticleTitle>}

              <StyledLink href={link} appearance="text">
                {t('readArticle')}
              </StyledLink>
            </ArticleGridContent>
          </Article>
        ))}
      </CardsWrap>
      {ctaLink && ctaLabel && (
        <CardLink appearance="containedTransparent" href={ctaLink.href}>
          {ctaLabel}
        </CardLink>
      )}
    </StyledSectionWrapper>
  )
}
